@charset "utf-8";

// htmlのクラス名はcamelCaseでエレメントは_でつなぐ
// htmlのmodは--でつなぐ
// sassの変数はcamelCase
// sassのミックスインは-でつなぐ
/////////////////////////////////////////////////////
// 変数定義
/////////////////////////////////////////////////////

$monts: "Montserrat", sans-serif;
$notos: "Noto Sans JP", sans-serif;
$footerColor: #998d05;
$modularscale: (
  base: 14px,
  ratio: $golden,
  ipad: (
    base: 16px,
    ratio: $golden,
  ),
  m: (
    base: 14px,
    ratio: $golden,
  ),
  l: (
    base: 16px,
    ratio: $golden,
  ),
  480px: (
    base: 8px,
    ratio: $golden,
  ),
  960px: (
    base: 12px,
    ratio: $golden,
  ),
  1600px: (
    base: 20px,
    ratio: $golden,
  ),
);

/////////////////////////////////////////////////////
// メディアクエリ
/////////////////////////////////////////////////////

// モバイルファースト
$breakpoints: (
  m: "(min-width: 480px)",
  ipad: "(min-width: 768px)",
  l: "(min-width: 960px)",
  xl: "(min-width: 1280px)",
);

@mixin media($breakpoint: ipad) {
  @if map-has-key($breakpoints, $breakpoint) {
    // マップにキーが有るか調べる
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    // なかった場合のエラー表示
    @error "$breakpointsにはキー #{$breakpoint} 　は存在しません";
  }
}
/////////////////////////////////////////////////////

/////////////////////////////////////////////////////
// フォント
/////////////////////////////////////////////////////

body {
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
}
h1 {
  letter-spacing: 0.05em;
  // font-family: $monts;
  font-weight: 400;
  font-size: ms(2);
  @include media(l) {
    font-size: ms(2, $thread: l);
  }
}

h3 {
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: ms(1);
  @include media(l) {
    font-size: ms(1, $thread: l);
  }
}

p {
  letter-spacing: 0.08em;
  // font-family: $notos;
  font-weight: 400;
  font-size: ms(0);
  @include media(l) {
    font-size: ms(0, $thread: l);
  }
}

a {
  text-decoration: none;
}

/////////////////////////////////////////////////////
// ミックスインでリンクホバーの呼び出し
/////////////////////////////////////////////////////

@mixin link-color($normal, $hover) {
  color: $normal;
  &:hover {
    text-decoration: none;
    color: $hover;
  }
}

@mixin button() {
  display: block;
  outline: none;
  padding: 11px 30px;
  border: 3px solid #000;
  border-radius: 70px;
  background: transparent;
  font-weight: 500;
  font-size: ms(1);
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  @include media(l) {
    font-size: ms(1, $thread: l);
  }
  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background: #000000;
    color: #e4e4e4;
    transform: translateY(-3px);
  }
}

@mixin button2() {
  display: block;
  outline: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  background: transparent;
  font-size: ms(0);
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  @include media(l) {
    font-size: ms(0, $thread: l);
  }
  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    transform: translateY(-3px);
  }
}
@mixin button3() {
  display: block;
  outline: none;
  padding: 10px 20px;
  border: 2px solid #000;
  background: transparent;
  font-weight: 500;
  font-size: ms(0);
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  @include media(l) {
    font-size: ms(0, $thread: l);
  }
  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background: #000;
    color: #fff;
    transform: translateY(-3px);
  }
}

@mixin topButton {
  #page_top {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 50px;
    height: 50px;
    background: #1e1e1e;

    @include media(m) {
      right: 30px;
      bottom: 40px;
    }
    a {
      display: block;
      position: relative;
      width: 50px;
      height: 50px;
      text-decoration: none;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 900;
        font-size: 25px;
        font-family: "Font Awesome 5 Free";
        color: #e4e4e4;
        content: "\f077";
        transform: translate(-50%, -50%);
      }
    }
  }
}
@mixin topButton2 {
  #page_top {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 50px;
    height: 50px;
    background: #e4e4e4;

    @include media(m) {
      right: 40px;
      bottom: 40px;
    }
    a {
      display: block;
      position: relative;
      width: 50px;
      height: 50px;
      text-decoration: none;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 900;
        font-size: 25px;
        font-family: "Font Awesome 5 Free";
        color: #1e1e1e;
        content: "\f077";
        transform: translate(-50%, -50%);
      }
    }
  }
}

/////////////////////////////////////////////////////
// z-index
/////////////////////////////////////////////////////
// マップ型の変数  呼び出しにはmap-get($変数, マップ名)
$layer: (
  modal: 100,
  header: 20,
  tooltip: 10,
  default: 1,
);

@mixin z-index($key: default) {
  z-index: map-get($layer, $key);
}

/////////////////////////////////////////////////////
// 埋め込み動画の比率
/////////////////////////////////////////////////////

@mixin rwd-iframe($width: 16, $height: 9) {
  position: relative;
  padding-top: percentage($height / $width); // 比率をパーセテージ化する計算
  > iframe {
    position: absolute; // 親要素上に表示

    top: 0%;
    left: 0%; // 余白を作る場合の中央指定、余白なしは0

    width: 100% !important;
    height: 100% !important; //余白なしは100%
  }
}
