.glbg {
  &_ab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 0;
    z-index: 0;
    text-shadow: 3px 3px 22px rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/images/about/img001.jpg);
    // margin-bottom: 30%;
  }
}

.abMain {
  background: #e4e4e4;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 10px;
  @include media(m) {
    padding: 50px 80px;
  }
  &_inner {
    margin: 70px 0;
  }
  p {
    // font-weight: 100;
    &.companyName {
      color: #d30707;
      font-weight: 500;
      font-size: 18px;
      margin-top: 25px;
    }
  }
  ul li {
    list-style-type: none;
    margin: 0.5em 0;
    font-family: $notos;
    font-weight: 100;
    font-size: ms(0);
    letter-spacing: 0.08em;
    @include media(l) {
      font-size: ms(0, $thread: l);
    }
  }
  ul {
    padding-left: 0.5em;
    &.companyInfo {
      margin-top: 2em;
      margin-bottom: 3em;
      li {
        font-size: 12px;
        &.companyListName {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  span {
    font-family: $monts;
    font-weight: 500;
  }
  // h1 {
  //   font-size: ms(3);
  //   margin: 20px 0;
  //   @include media(l) {
  //     display: none;
  //     font-size: ms(3, $thread: l);
  //   }
  // }
  h3 {
    font-weight: 400;
    font-size: ms(1);
    @include media(l) {
      font-size: ms(1, $thread: l);
    }
  }
  h4 {
    font-family: $monts;
    font-weight: 500;
    font-size: ms(0);
    @include media(l) {
      font-size: ms(1, $thread: l);
    }
  }
  .name {
    font-size: ms(0);
    font-family: $monts;
    font-weight: 500;
    @include media(l) {
      font-size: ms(1, $thread: l);
    }
  }
}
