.glbg {
  &_wo {
    display: flex;
    position: relative;
    bottom: 0;
    z-index: 0;
    width: 100%;
    min-height: 100%;
    background-image: url(/images/works/bgimg001.jpg);
    background-position: bottom 30% center;
    background-repeat: no-repeat;
    background-size: cover;

    align-items: center;
    // margin-bottom: 30%;
  }
}
.woMain_bg {
  background: #e4e4e4;
}
.woMain {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 10px;
  background: #e4e4e4;
  @include media(m) {
    padding: 50px 40px;
  }

  @include topButton;
  ul li {
    list-style-type: none;
    margin: 0.5em 0;
    letter-spacing: 0.08em;
    font-size: ms(0);
    @include media(l) {
      font-size: ms(0, $thread: l);
    }
  }
  ul {
    padding: 0;
  }
  span {
    // font-size: ms(1, $thread: s);
    @include media(l) {
      // font-size: ms(1, $thread: l);
    }
  }
  h3 {
    margin: 40px 0;
    font-weight: 500;
  }
  h4 {
    margin-left: 0.5em;
    font-weight: 500;
    font-size: ms(1);
    @include media(m) {
      font-size: ms(1);
    }
    @include media(l) {
      font-size: ms(1, $thread: l);
    }
  }
  &_index {
    margin-bottom: 100px;
    h2 {
      font-size: ms(1, $thread: s);
    }
    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      font-family: $monts;
      color: #fff;
      flex-wrap: wrap;
      a {
        color: #fff;
      }
      li {
        margin: 10px 10px 0 0;
        font-size: ms(0, $thread: s);
        @include button3;
      }
    }
  }
  &_vimeo {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include media(l) {
      display: flex;
      flex-direction: row;
      div {
        width: 50%;
        margin: 10px;
        padding: 28.12% 0 0 0;
      }
    }
    div {
      position: relative;
      width: 100%;
      margin: 20px 0;
      padding: 56.25% 0 0 0;
    }
  }
  &_works {
    width: 80%;
    margin: 0 auto;

    @include media(m) {
      width: 100%;
      max-width: 700px;
    }

    @include media(l) {
      display: flex;
      max-width: 98%;
      flex-wrap: wrap;
    }
    &_text {
      // margin-left: 30px;
      width: 100%;
      button {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 8px 30px;
        border: 2px solid #000;
        @include button();
        p {
          margin: 0;
          font-weight: 500;
        }
        // border-radius: 20px;
      }
    }
    &_inner {
      // text-align: center;
      margin: 50px 0;
      @include media(m) {
        display: flex;
        justify-content: space-between;
        div {
          width: 50%;
          margin: 0 20px;
          text-align: right;
        }
      }
      @include media(l) {
        width: 50%;
        div {
          text-align: left;
        }
      }
      img {
        max-width: 280px;
        width: 100%;
      }
      a {
        max-height: 380px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .workTitle {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: ms(0, $thread: s);
    @include media(l) {
      font-weight: 700;
      font-size: ms(0, $thread: l);
    }
  }
  &_iframe {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ifr_YT img{
      cursor: pointer
    }
    .ifr_YT_after {
      @include rwd-iframe;
    }
    @include media(l) {
      flex-direction: row;
      flex-wrap: wrap;
      &_inner {
        width: 50%;
        height: 550px;
        padding: 40px 20px;
        text-align: center;
      }
    }
    &_inner {
      width: 100%;
      padding: 40px 0px;
      text-align: center;
    }
  }
}
