.footer {
  width: 100%;
  background: #0a0a0a;
  padding: 30px 30px 20px 30px;
  text-align: center;
  &_sign {
    width: 100px;
    margin: 0 auto;
    svg {
      width: 100px;
      height: 100px;
      border: 3px solid $footerColor;
      padding: 10px;
      fill: $footerColor;
    }
  }
  &_contact {
    margin: 20px 0;
    .i-mail {
      color: $footerColor;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45+95px;
      svg {
        width: 45px;
        height: 30px;
        fill: $footerColor;
        padding-right: 5px;
        transition: all 300ms;
      }
      p {
        margin: 0;
        font-family: $monts;
        transition: all 300ms;
      }
      &:hover {
        color: lighten($color: $footerColor, $amount: 20%);
        svg {
          fill: lighten($color: $footerColor, $amount: 20%);
        }
      }
    }
  }
  &_bd {
    min-width: 200px;
    max-width: 250px;
    height: 1px;
    background: $footerColor;
    margin: 0 auto;
  }
  &_links {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    a {
      position: relative;
      margin: 0 5px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid $footerColor;
      transition: all 300ms;
      .iStyle,
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        transition: all 300ms;
      }
      .iStyle {
        width: 20px;
        height: 20px;
        fill: $footerColor;
      }
      i {
        font-size: 20px;
        color: $footerColor;
      }
      &:hover {
        border: 1px solid lighten($footerColor, 20%);
        .iStyle,
        i {
          fill: lighten($footerColor, 20%);
          color: lighten($color: $footerColor, $amount: 20%);
        }
      }
    }
  }
  .small {
    margin-top: 25px;
    font-family: $monts;
    font-weight: 400;
    color: $footerColor;
    font-size: ms(0);
  }
}
