.glbg {
  &_co {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100%;
    position: relative;
    bottom: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom 30% center;
    background-image: url(/images/gallery/2020/img008.jpg);
    // margin-bottom: 30%;
  }
}

.coMain {
  background: #e4e4e4;
  // max-width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  padding: 50px 10px;
  text-align: center;
  vertical-align: middle;
  @include media(m) {
    padding: 50px 80px;
  }
  p {
    font-weight: 100;
  }
  span {
    font-family: $monts;
    font-weight: 500;
  }
  h2 {
    font-size: ms(1);
    font-family: $monts;
    font-weight: 500;
    // margin-top: 70px;
    @include media (l) {
    font-size: ms(1, $thread: l);
    }
    a {
      color: $footerColor;
      text-decoration: underline;
    }
  }
}
