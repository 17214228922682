.loading {
  width: 100%;
  position: fixed;
  z-index: 150;
  height: 100%;
  background: #0d0d0d;
  transition: 800ms all cubic-bezier(0.86, 0, 0.07, 1) 100ms;
  &_wrap {
    top: 47vh;
    left: 50vw;
    width: 120px;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: 400ms all ease;
    p {
      @keyframes inOut {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      font-size: 14px;
      margin-top: 10px;
      color: #fff;
      // font-family: $monts;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      margin: 0 auto;
      font-weight: 500;
      animation: inOut 500ms infinite alternate forwards;
    }
    .doorIcon {
      width: 70%;
    }
    .lineWidth {
      position: relative;
      width: 40vw;
      height: 2px;
      background: #5a5a5a;
      margin-top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    &_line {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 2px;
      background: #fff;
      z-index: 1;
    }
  }
}

.hide {
  transform: translateX(100%);
  .loading {
    &_wrap {
      opacity: 0;
    }
  }
}

.header {
  &_bg {
    width: 100%;
    margin: 0;
    padding: 0;
    &_slide {
      padding: 0;
      min-height: 450px;
      div {
        height: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom 10% center;
      }
      // 背景イメージの読み込み
      $tmp: "";
      // 先に変数を定義する
      @for $i from 1 through 5 {
        @if $i < 10 {
          $tmp: "00#{$i}";
        } @else if $i < 100 {
          $tmp: "0#{$i}";
        } @else {
          $tmp: $i;
        }
        // ゼロパディングは3桁
        .bgimg_#{$tmp} {
          background-image: url(/images/bgimgsp_#{$tmp}.jpg);
          @include media(m) {
            background-image: url(/images/bgimg_#{$tmp}.jpg);
          }
        }
      }
    }

    &_scrIcon {
      position: absolute;
      top: 50%;
      width: 20px;
      right: 10px;
      margin: 0;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.8));
      transform: translateY(-40%);
      @include z-index(tooltip);
      p {
        position: relative;
        margin: 0;
        max-height: 91px;
        margin-bottom: 10px;
        width: 20px;
        font-weight: 500;
        font-size: ms(0);
        color: #fff;
        writing-mode: vertical-lr;
        letter-spacing: 0.4em;
      }
      .animeIcon {
        position: relative;
        // display: flex;
        // align-items: center;
        width: 20px;
        overflow: hidden;
        flex-flow: column;
        .circle {
          position: absolute;
          top: -15px;
          left: 50%;
          width: 10px;
          height: 10px;
          border: 2px solid #fff;
          border-radius: 50%;
          animation: scrlDown 2.5s ease 0s infinite normal;
        }
        .line {
          position: relative;
          top: -95px;
          left: 50%;

          width: 1.5px;
          height: 80px;
          background: #fff;
          animation: scrlDown 2.5s ease 0s infinite normal;
        }
        @keyframes scrlDown {
          0% {
            transform: translate3d(-50%, 0, 0);
          }
          100% {
            transform: translate3d(-50%, 180px, 0);
          }
        }
      }
    }
  }
  &_trg {
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    @include z-index(modal);
    border-top: 37px solid rgba($color: #000, $alpha: 0.7);
    border-right: 60px solid rgba($color: #000, $alpha: 0.7);
    border-bottom: 37px solid transparent;
    border-left: 60px solid transparent;
    transition: all 500ms;
    @include media(m) {
      border-top: 49px solid rgba($color: #000, $alpha: 0.7);
      border-right: 80px solid rgba($color: #000, $alpha: 0.7);
      border-bottom: 49px solid transparent;
      border-left: 80px solid transparent;
    }
    @include media(l) {
      border-top: 49px solid rgba($color: #000, $alpha: 0.7);
      border-right: 129px solid rgba($color: #000, $alpha: 0.7);
      border-bottom: 49px solid transparent;
      border-left: 129px solid transparent;
    }
  }
  &_hmg {
    @include z-index(modal);
    position: fixed;
    top: 10px;
    right: 15px;
    cursor: pointer;
    p {
      display: none;
    }
    &_btn {
      position: relative;
      width: 25px;
      height: 25px;
      span {
        position: absolute;
        width: 25px;
        height: 2px;
        background: #fff;
        transition: all 500ms;
        &:nth-child(1) {
          top: 30%;
        }
        &:nth-child(2) {
          bottom: 30%;
        }
      }
    }
    @include media(m) {
      right: 18px;
      &_btn {
        width: 40px;
        height: 40px;
        span {
          width: 40px;
        }
      }
    }
    @include media(l) {
      top: 15px;
      display: flex;
      &_btn {
        width: 30px;
        height: 30px;
        span {
          width: 30px;
        }
      }
      p {
        position: relative;
        // cursor: pointer;
        display: block;
        color: #fff;
        margin: auto 10px;
        margin-top: 7.5px;
        font-weight: 500;
        letter-spacing: 0.15em;
        font-size: ms(0, $thread: l);
        line-height: 1;
      }
    }
  }

  &_nav {
    position: fixed;
    visibility: hidden;
    top: 0;
    padding-left: 20px;
    right: 0px;
    width: 100%;
    min-height: 320px;
    background: rgba($color: #000, $alpha: 0.9);
    opacity: 0;
    transition: all 600ms;
    @include z-index(header);
    ul {
      margin: 0;
      padding: 70px 0 40px 0;
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
    li {
      position: relative;
      right: -300px;
      font-size: ms(1);
      font-family: $monts;
      letter-spacing: normal;
      padding: 0 30px;
      text-align: right;
      font-weight: 500;
      transition: transform 0.4s ease, opacity 0.2s ease;
      font-size: 20px;
      letter-spacing: 0.2em;
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.2s;
      }
      &:nth-child(4) {
        transition-delay: 0.3s;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
      }
      &:nth-child(6) {
        transition-delay: 0.5s;
      }
      a {
        color: #fff;
      }
    }
    &.scrl {
      background: rgba($color: #000, $alpha: 0.95);
      padding: 60px 30px 80px 30px;
      width: 600px;
      // transform: scaleX(1.2);
    }
    &_text {
      display: none;
    }
    @include media() {
      ul {
        padding-top: 120px;
        padding-bottom: 50px;
        padding-right: 60px;
      }
    }
    @include media(l) {
      display: flex;
      flex-flow: column;
      // justify-content: space-around;
      background: rgba($color: #000, $alpha: 0.7);
      width: 600px;
      li {
        font-size: ms(1, $thread: l);
      }
      ul {
        flex-basis: 78%;
      }
      &_text {
        margin-left: 40px;
        display: block;
        flex-basis: 22%;
        h3 {
          color: #fff;
          font-family: $monts;
          font-weight: 400;
          margin-top: 0;
          margin-bottom: 10px;
          font-size: ms(1, $thread: l);
        }
        p {
          color: #fff;
          font-weight: 100;
          font-size: 11px;
        }
      }
    }
  }
  &_inner {
    width: 100%;
    position: relative;
    background: #0a0a0a;
    &_text {
      text-align: center;
      h1 {
        padding-top: 10px;
        color: #fff;
        font-weight: 500;
        font-size: ms(3);
        margin: 0;
        line-height: 1.3em;
        letter-spacing: 0;
      }
      div {
        display: none;
      }
      p {
        color: #fff;
        margin: 0;
        a {
          color: #fff;
          transition: all 300ms;
          &:hover {
            color: darken($color: #fff, $amount: 20%);
          }
        }
      }
      @include media(l) {
        text-align: left;
        display: flex;
        h1 {
          padding: 0;
          margin-left: 30px;
          font-size: ms(3, $thread: l);
          flex-shrink: 0;
        }
        div {
          display: block;
          width: 3px;
          height: 60px;
          background: #fff;
          margin: 0 20px;
          align-self: center;
        }
        p {
          align-self: flex-end;
          margin-bottom: 18px;
        }
      }
    }
    &_links {
      margin: 20px;
      display: flex;
      justify-content: center;
      a {
        position: relative;
        margin: 0 10px;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #fff;
      }
      .iStyle,
      i {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        margin: auto;
      }
      .iStyle {
        width: 17px;
        pointer-events: none;
      }
      i {
        font-size: 17px;
      }
      .i-mail {
        display: none;
      }
      .i-cart {
        background: #009a9c;
        svg {
          fill: #fff;
        }
      }
      .i-blog {
        background: #f57d00;
      }
      .i-twt {
        background: #1da1f2;
      }
      .i-inst {
        background: #c13584;
      }
    }
    @include media(l) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_links {
        margin: 0;
        margin-right: 20px;
        .i-mail {
          color: #fff;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45+95px;
          .mail {
            position: relative;
            width: 45px;
            height: 30px;
            fill: #fff;
            padding-right: 5px;
            transition: all 300ms;
          }
          p {
            margin: 0;
            transition: all 300ms;
          }
          &:hover {
            color: darken($color: #fff, $amount: 20%);
            svg {
              fill: darken($color: #fff, $amount: 20%);
            }
          }
        }
      }
    }
  }
  &_cover {
    visibility: hidden;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 15;
    transition: all 300ms;
    background: #0a0a0a;
    opacity: 0;
  }
}

.open .header {
  &_hmg {
    span:nth-child(1) {
      // width: 30px;
      // height: 1.5px;
      transform: translateY(-50%) rotate(45deg);
      top: 70%;
      @include media(l) {
        top: 50%;
      }
    }
    span:nth-child(2) {
      // width: 30px;
      // height: 1.5px;
      transform: translateY(50%) rotate(-45deg);
      @include media(l) {
        bottom: 50%;
      }
    }
  }
  &_trg {
    transform: translate(0, -200px);
  }
  &_nav {
    // transform: translate(-500px, 0);
    visibility: visible;
    opacity: 1;
    li {
      opacity: 1;
      transform: translate(-300px, 0);
    }
  }
  &_cover.scrl {
    @include media(l) {
      visibility: visible;
      opacity: 0.7;
    }
  }
}

.slide-dots {
  position: absolute;
  bottom: 20px;
  z-index: 12;
  list-style-type: none;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0;
  @include media(l) {
    left: 50px;
    transform: translateX(0);
  }
  li {
    margin-right: 32px;
    &:last-child {
      margin: 0;
    }
    div {
      cursor: pointer;
      width: 16px;
      height: 16px;
      // background: #fff;
      border: 8px solid #fff;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.41));
      transition: all 300ms;
      &:hover {
        transform: translateY(-3px);
      }
      @include media(m) {
        width: 18px;
        height: 18px;
        border: 9px solid #fff;
      }
    }
    &.slick-active div {
      border: 3px solid #fff;
      background: none;
      &:hover {
        transform: translateY(0px);
      }
    }
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.notice {
  width: 100%;
  background: #eefe05;
  height: 100%;
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding: 1em 1em;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  @include media(m) {
    height: 100%;
    padding: 0.7em 0;
  }
  p {
    margin: 0.7em 0;
    font-weight: 600;
    font-size: ms(0, $thread: s);
    a {
      margin: 0.5em;
      text-decoration: underline;
      color: #000;
      font-weight: 500;
      font-size: ms(0, $thread: s);
      transition: all 150ms;
      &:hover {
        color: lighten($color: #000, $amount: 40%);
      }
    }
  }
  button {
    margin-left: 1em;
    cursor: pointer;
    color: #000;
    display: block;
    background: transparent;
    transition: all 0.3s ease;
    border: 2px solid #000;
    border-radius: 70px;
    padding: 5px 10px;
    font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
    font-weight: 500;
    outline: none;
    font-size: 12px;
    &:hover {
      color: #e4e4e4;
      background: #000000;
    }
  }
}

.main {
  background: #e4e4e4;
  padding: 50px 15px;
  // max-width: 1200px;
  margin: 0 auto;
  @include media(m) {
    padding: 50px;
  }
  @include media(l) {
    h1 {
      letter-spacing: 0.1em;
    }
  }

  &_wrap {
    max-width: 900px;
    margin: 0 auto;
  }
  .main_flex {
    br {
      display: none;
    }
    &_text {
      margin: 50px 0;
      p {
        line-height: 37px;
        margin: 30px 0;
      }
      .buttons {
        margin-top: 50px;
        @include media(l) {
          margin-top: 100px;
          // display: flex;
        }
        // justify-content: space-between;
        button {
          margin: 0 auto;
          margin-top: 50px;
          @include button();
          @include media(m) {
            margin: 60px 20px 0 0;
          }
        }
      }
    }
    &_img {
      text-align: center;
      img {
        max-width: 100%;
        max-height: 600px;
      }
    }
    @include media() {
      display: flex;
      justify-content: space-between;
      br {
        display: block;
      }
      &_text {
        flex: 0 0 60%;
        margin: 0;
      }
      &_img {
        margin-top: 50px;
      }
    }
  }
}

.news {
  background-image: url(/images/imgNews.jpg);
  // max-width: 1200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 0 right 25%;
  // max-width: 1200px;

  // padding: 50px 15px;
  position: relative;
  @include media(m) {
    padding: 0px 0px;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    background: rgba($color: #000, $alpha: 0.4);
  }
  &_text {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 30px;
    position: relative;
    @include z-index(tooltip);
    &_wrap {
      // display: flex;
      padding: 15px 0;
      border-top: 1px solid #f5f5f5;
      &:last-child {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 40px;
      }
    }
    h1 {
      color: #fff;
    }
    h3 {
      font-weight: 400;
      min-width: 150px;
      margin: 0;
      color: #fff;
    }
    p {
      color: #fff;
      font-weight: 300;
      margin: 8px 0;
    }
    a {
      color: #fff;
      // font-weight: 300;
      margin: 8px 0;
      font-size: 14px;
      text-decoration: underline;
      letter-spacing: 0.1em;
      font-weight: 300;
      transition: all 300ms;
      &:hover {
        color: darken($color: #fff, $amount: 10%);
      }
      @include media(m) {
        font-size: 16px;
      }
    }
    @include media(m) {
      &_wrap {
        display: flex;
        // margin-bottom: 1.5em;
        padding: 15px 0;
        // padding-bottom: 0.2em;
        border-top: 1px solid #f5f5f5;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: 1px solid #f5f5f5;
        }
      }
      p,
      h3,
      a {
        margin: 0;
      }
      p,
      a {
        text-align: right;
      }
      @include media(l) {
        padding: 10px 150px;
        br {
          display: none;
        }
      }
    }
  }
}

.works {
  background: #e4e4e4;
  padding: 50px 15px;
  // max-width: 1200px;
  margin: 0 auto;
  @include media(m) {
    padding: 50px 50px;
  }
  h3 {
    bottom: 30px;
  }

  &_wrap {
    max-width: 900px;
    margin: 0 auto;
    p {
      margin: 0;
      line-height: 2.5;
      font-size: ms(1);
      @include media(l) {
        font-size: ms(1, $thread: l);
      }
    }

    button {
      @include button();
      margin: 40px auto 20px auto;
      @include media(m) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    @include media(m) {
      br {
        display: none;
      }
    }
  }
}
