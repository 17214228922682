@charset "UTF-8";

/* Document
 * ========================================================================== */

/**
 * Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * Collapse border spacing in all browsers (opinionated).
 */

table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */

fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */

textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* Slider */

.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */

@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

body {
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
}

h1 {
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 36.65248px;
}

h3 {
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 22.65248px;
}

p {
  letter-spacing: 0.08em;
  font-weight: 400;
  font-size: 14px;
}

a {
  text-decoration: none;
}

.footer {
  width: 100%;
  background: #0a0a0a;
  padding: 30px 30px 20px 30px;
  text-align: center;
}

.footer_sign {
  width: 100px;
  margin: 0 auto;
}

.footer_sign svg {
  width: 100px;
  height: 100px;
  border: 3px solid #998d05;
  padding: 10px;
  fill: #998d05;
}

.footer_contact {
  margin: 20px 0;
}

.footer_contact .i-mail {
  color: #998d05;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
}

.footer_contact .i-mail svg {
  width: 45px;
  height: 30px;
  fill: #998d05;
  padding-right: 5px;
  transition: all 300ms;
}

.footer_contact .i-mail p {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 300ms;
}

.footer_contact .i-mail:hover {
  color: #f7e40d;
}

.footer_contact .i-mail:hover svg {
  fill: #f7e40d;
}

.footer_bd {
  min-width: 200px;
  max-width: 250px;
  height: 1px;
  background: #998d05;
  margin: 0 auto;
}

.footer_links {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.footer_links a {
  position: relative;
  margin: 0 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #998d05;
  transition: all 300ms;
}

.footer_links a .iStyle,
.footer_links a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: all 300ms;
}

.footer_links a .iStyle {
  width: 20px;
  height: 20px;
  fill: #998d05;
}

.footer_links a i {
  font-size: 20px;
  color: #998d05;
}

.footer_links a:hover {
  border: 1px solid #f7e40d;
}

.footer_links a:hover .iStyle,
.footer_links a:hover i {
  fill: #f7e40d;
  color: #f7e40d;
}

.footer .small {
  margin-top: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #998d05;
  font-size: 14px;
}

.glHeader {
  height: 60px;
}

.glHeader_hmg {
  z-index: 100;
  position: fixed;
  top: 30px;
  padding: 10px;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
}

.glHeader_hmg p {
  display: none;
}

.glHeader_hmg_btn {
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.glHeader_hmg_btn span {
  position: absolute;
  width: 25px;
  height: 2px;
  background: #fff;
  transition: all 500ms;
}

.glHeader_hmg_btn span:nth-child(1) {
  top: 30%;
}

.glHeader_hmg_btn span:nth-child(2) {
  bottom: 30%;
}

.glHeader_nav {
  position: fixed;
  visibility: hidden;
  bottom: 0;
  padding-left: 20px;
  right: 0px;
  width: 100%;
  min-height: 320px;
  background: #0a0a0a;
  opacity: 0;
  transition: all 600ms;
  z-index: 20;
}

.glHeader_nav ul {
  margin: 0;
  padding: 70px 0 40px 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.glHeader_nav li {
  position: relative;
  right: -300px;
  font-size: 22.65248px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
  padding: 0 30px;
  text-align: right;
  font-weight: 500;
  transition: transform 0.4s ease, opacity 0.2s ease;
  font-size: 20px;
  letter-spacing: 0.2em;
}

.glHeader_nav li:nth-child(2) {
  transition-delay: 0.1s;
}

.glHeader_nav li:nth-child(3) {
  transition-delay: 0.2s;
}

.glHeader_nav li:nth-child(4) {
  transition-delay: 0.3s;
}

.glHeader_nav li:nth-child(5) {
  transition-delay: 0.4s;
}

.glHeader_nav li:nth-child(6) {
  transition-delay: 0.5s;
}

.glHeader_nav li a {
  color: #fff;
}

.glHeader_nav_text {
  display: none;
}

.glHeader_nav_links {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  transform: translateY(320px);
  flex-direction: column;
  justify-content: center;
  transition: all 500ms;
}

.glHeader_nav_links a {
  position: relative;
  margin: 20px 10px 20px 0;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
}

.glHeader_nav_links .iStyle,
.glHeader_nav_links i {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
}

.glHeader_nav_links .iStyle {
  width: 20px;
  pointer-events: none;
}

.glHeader_nav_links i {
  font-size: 20px;
}

.glHeader_nav_links .i-mail {
  display: none;
}

.glHeader_nav_links .i-cart svg {
  fill: #fff;
}

.glHeader_inner {
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  background: #0a0a0a;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.glHeader_inner_text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 15px;
}

.glHeader_inner_text .titleName {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 22.65248px;
  margin: 0;
  line-height: 1em;
}

.glHeader_inner_text .titleName span {
  font-size: 8.65248px;
  margin-left: 8px;
  font-weight: 300;
}

.glHeader_inner_text div {
  display: none;
}

.glHeader_inner_text p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin: 0;
  font-size: 5.34752px;
}

.glHeader_inner_text p a {
  color: #fff;
  transition: all 300ms;
}

.glHeader_inner_text p a:hover {
  color: #cccccc;
}

.glHeader_cover {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 800px;
  z-index: 15;
  transition: all 300ms;
  background: #0a0a0a;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-45%);
  }

  100% {
    opacity: 1;
    transform: translateY(-50%);
  }
}

.glbg {
  width: 100%;
  min-height: 400px;
  position: relative;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.2);
}

.glbg h1 {
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 500;
  margin: 0;
  opacity: 0;
  animation: fadeIn 1200ms ease 1000ms forwards;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 3px 3px 22px rgba(0, 0, 0, 0.6);
  font-size: 54.83282px;
}

.glbg_scrIcon {
  display: none;
  position: absolute;
  width: 20px;
  margin: 0;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.8));
  z-index: 3;
}

.glbg_scrIcon p {
  position: relative;
  margin: 0;
  max-height: 91px;
  margin-bottom: 10px;
  width: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  writing-mode: vertical-lr;
  letter-spacing: 0.4em;
}

.glbg_scrIcon .animeIcon {
  position: relative;
  width: 20px;
  overflow: hidden;
  flex-flow: column;
}

.glbg_scrIcon .animeIcon .circle {
  position: absolute;
  top: -15px;
  left: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: scrlDown 2.5s ease 0s infinite normal;
}

.glbg_scrIcon .animeIcon .line {
  position: relative;
  top: -95px;
  left: 50%;
  width: 1.5px;
  height: 80px;
  background: #fff;
  animation: scrlDown 2.5s ease 0s infinite normal;
}

@keyframes scrlDown {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 180px, 0);
  }
}

.open .glHeader_hmg span:nth-child(1) {
  top: 70%;
  transform: translateY(-50%) rotate(45deg);
}

.open .glHeader_hmg span:nth-child(2) {
  transform: translateY(50%) rotate(-45deg);
}

.open .glHeader_nav {
  visibility: visible;
  opacity: 1;
}

.open .glHeader_nav li {
  opacity: 1;
  transform: translate(-300px, 0);
}

.open .glHeader_nav_text {
  transform: translateY(0);
}

.open .glHeader_nav_links {
  transform: translateY(0px);
}

.glbg_ab {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  z-index: 0;
  text-shadow: 3px 3px 22px rgba(0, 0, 0, 0.6);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(/images/about/img001.jpg);
}

.abMain {
  background: #e4e4e4;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 10px;
}

.abMain_inner {
  margin: 70px 0;
}

.abMain p.companyName {
  color: #d30707;
  font-weight: 500;
  font-size: 18px;
  margin-top: 25px;
}

.abMain ul li {
  list-style-type: none;
  margin: 0.5em 0;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 0.08em;
}

.abMain ul {
  padding-left: 0.5em;
}

.abMain ul.companyInfo {
  margin-top: 2em;
  margin-bottom: 3em;
}

.abMain ul.companyInfo li {
  font-size: 12px;
}

.abMain ul.companyInfo li.companyListName {
  font-size: 14px;
  font-weight: 500;
}

.abMain span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.abMain h3 {
  font-weight: 400;
  font-size: 22.65248px;
}

.abMain h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.abMain .name {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.glbg_co {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  bottom: 0;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 30% center;
  background-image: url(/images/gallery/2020/img008.jpg);
}

.coMain {
  background: #e4e4e4;
  min-height: 300px;
  margin: 0 auto;
  padding: 50px 10px;
  text-align: center;
  vertical-align: middle;
}

.coMain p {
  font-weight: 100;
}

.coMain span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.coMain h2 {
  font-size: 22.65248px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.coMain h2 a {
  color: #998d05;
  text-decoration: underline;
}

.glbg_ev {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  bottom: 0;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 30% center;
  background-image: url(/images/event/bgimg001.jpg);
}

.evMain {
  background: #e4e4e4;
  max-width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  padding: 50px 10px;
  vertical-align: middle;
}

.evMain ul {
  list-style-type: none;
  padding-left: 0;
}

.evMain ul li {
  letter-spacing: 0.1em;
}

.evMain span {
  font-weight: 500;
}

.evMain h2 {
  font-size: 22.65248px;
  font-weight: 500;
}

.evMain h2 a {
  color: #998d05;
  text-decoration: underline;
}

.evMain button {
  display: block;
  outline: none;
  padding: 11px 30px;
  border: 3px solid #000;
  border-radius: 70px;
  background: transparent;
  font-weight: 500;
  font-size: 22.65248px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 0;
  border: 2px solid #000;
  display: inline;
  margin-right: 1em;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 2px 10px;
  font-size: 14px;
}

.evMain button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #000000;
  color: #e4e4e4;
  transform: translateY(-3px);
}

.evMain button:hover {
  transform: translateY(0);
  box-shadow: none;
}

.evMain .status {
  color: #d30707;
  font-weight: 500;
  font-size: 14px;
  margin-right: 1em;
}

.evMain_list {
  padding: 0;
  margin-bottom: 50px;
  margin-left: 1em;
}

.evMain_list p {
  margin: 0;
  line-height: 2.5;
}

.evMain_list li {
  margin-bottom: 30px;
}

.evMain_commet {
  margin: 1em 0 1em 1em;
}

.evMain_commet li {
  font-size: 12px;
}

.evMain_detail {
  width: 80%;
  margin: 0 auto;
}

.evMain_detail_inner {
  margin: 30px 0;
}

.evMain_detail_inner li {
  font-size: 12px;
}

.evMain_detail_inner img {
  width: 100%;
}

.evMain_detail_inner .disNone {
  display: none;
}

.evMain_detail_inner h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.6em;
}

.evMain_detail_inner figure {
  display: none;
}

.evMain_detail_inner button {
  margin-bottom: 16px;
}

.evMain_detail_inner .bookTitle {
  font-size: 22.65248px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0;
}

.pfMain {
  background: #000;
  padding: 30px 2px;
}

.pfMain .dummy {
  display: none;
}

.pfMain h3,
.pfMain p {
  color: #fff;
}

.pfMain h1 {
  color: #fff;
  margin: 0;
  margin-bottom: 70px;
}

.pfMain h3 {
  margin-bottom: 8px;
  margin-top: 100px;
}

.pfMain h2 {
  color: #fff;
  font-weight: 500;
}

.pfMain p {
  font-weight: normal;
  margin-bottom: 61px;
}

.pfMain_wrap {
  max-width: 1200px;
  width: 100%;
  padding: 0 5px;
}

.pfMain_wrap .explanation {
  color: #FFAA2A;
}

.pfMain_wrap_index ul {
  list-style-type: none;
  padding: 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.pfMain_wrap_index ul a {
  color: #fff;
}

.pfMain_wrap_index ul li {
  display: block;
  outline: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  background: transparent;
  font-size: 14px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  margin: 10px 10px 0 0;
}

.pfMain_wrap_index ul li:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  transform: translateY(-3px);
}

.pfMain_gallery {
  display: flex;
  flex-wrap: wrap;
}

.pfMain_gallery a {
  margin: 0;
  position: relative;
  width: 50%;
  padding: 2px;
}

.pfMain_gallery a figure {
  display: none;
}

.pfMain_gallery a img {
  max-width: 100%;
  max-height: 100%;
  transition: 0.4s;
}

.pfMain_gallery a:hover img {
  opacity: 0.7;
}

.pfMain_gallery a.ok::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(/images/license.png);
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.pfMain #page_top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  background: #e4e4e4;
}

.pfMain #page_top a {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

.pfMain #page_top a:before {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 900;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
  color: #1e1e1e;
  content: "\f077";
  transform: translate(-50%, -50%);
}

.okimg {
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.5em;
  position: absolute;
  top: 0%;
  bottom: 9%;
  margin: auto;
}

.loading {
  width: 100%;
  position: fixed;
  z-index: 150;
  height: 100%;
  background: #0d0d0d;
  transition: 800ms all cubic-bezier(0.86, 0, 0.07, 1) 100ms;
}

.loading_wrap {
  top: 47vh;
  left: 50vw;
  width: 120px;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 400ms all ease;
}

.loading_wrap p {
  font-size: 14px;
  margin-top: 10px;
  color: #fff;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  margin: 0 auto;
  font-weight: 500;
  animation: inOut 500ms infinite alternate forwards;
}

@keyframes inOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading_wrap .doorIcon {
  width: 70%;
}

.loading_wrap .lineWidth {
  position: relative;
  width: 40vw;
  height: 2px;
  background: #5a5a5a;
  margin-top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.loading_wrap_line {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background: #fff;
  z-index: 1;
}

.hide {
  transform: translateX(100%);
}

.hide .loading_wrap {
  opacity: 0;
}

.header_bg {
  width: 100%;
  margin: 0;
  padding: 0;
}

.header_bg_slide {
  padding: 0;
  min-height: 450px;
}

.header_bg_slide div {
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 10% center;
}

.header_bg_slide .bgimg_001 {
  background-image: url(/images/bgimgsp_001.jpg);
}

.header_bg_slide .bgimg_002 {
  background-image: url(/images/bgimgsp_002.jpg);
}

.header_bg_slide .bgimg_003 {
  background-image: url(/images/bgimgsp_003.jpg);
}

.header_bg_slide .bgimg_004 {
  background-image: url(/images/bgimgsp_004.jpg);
}

.header_bg_slide .bgimg_005 {
  background-image: url(/images/bgimgsp_005.jpg);
}

.header_bg_scrIcon {
  position: absolute;
  top: 50%;
  width: 20px;
  right: 10px;
  margin: 0;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.8));
  transform: translateY(-40%);
  z-index: 10;
}

.header_bg_scrIcon p {
  position: relative;
  margin: 0;
  max-height: 91px;
  margin-bottom: 10px;
  width: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  writing-mode: vertical-lr;
  letter-spacing: 0.4em;
}

.header_bg_scrIcon .animeIcon {
  position: relative;
  width: 20px;
  overflow: hidden;
  flex-flow: column;
}

.header_bg_scrIcon .animeIcon .circle {
  position: absolute;
  top: -15px;
  left: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: scrlDown 2.5s ease 0s infinite normal;
}

.header_bg_scrIcon .animeIcon .line {
  position: relative;
  top: -95px;
  left: 50%;
  width: 1.5px;
  height: 80px;
  background: #fff;
  animation: scrlDown 2.5s ease 0s infinite normal;
}

@keyframes scrlDown {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 180px, 0);
  }
}

.header_trg {
  position: fixed;
  opacity: 1;
  top: 0;
  right: 0;
  z-index: 100;
  border-top: 37px solid rgba(0, 0, 0, 0.7);
  border-right: 60px solid rgba(0, 0, 0, 0.7);
  border-bottom: 37px solid transparent;
  border-left: 60px solid transparent;
  transition: all 500ms;
}

.header_hmg {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.header_hmg p {
  display: none;
}

.header_hmg_btn {
  position: relative;
  width: 25px;
  height: 25px;
}

.header_hmg_btn span {
  position: absolute;
  width: 25px;
  height: 2px;
  background: #fff;
  transition: all 500ms;
}

.header_hmg_btn span:nth-child(1) {
  top: 30%;
}

.header_hmg_btn span:nth-child(2) {
  bottom: 30%;
}

.header_nav {
  position: fixed;
  visibility: hidden;
  top: 0;
  padding-left: 20px;
  right: 0px;
  width: 100%;
  min-height: 320px;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: all 600ms;
  z-index: 20;
}

.header_nav ul {
  margin: 0;
  padding: 70px 0 40px 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.header_nav li {
  position: relative;
  right: -300px;
  font-size: 22.65248px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
  padding: 0 30px;
  text-align: right;
  font-weight: 500;
  transition: transform 0.4s ease, opacity 0.2s ease;
  font-size: 20px;
  letter-spacing: 0.2em;
}

.header_nav li:nth-child(2) {
  transition-delay: 0.1s;
}

.header_nav li:nth-child(3) {
  transition-delay: 0.2s;
}

.header_nav li:nth-child(4) {
  transition-delay: 0.3s;
}

.header_nav li:nth-child(5) {
  transition-delay: 0.4s;
}

.header_nav li:nth-child(6) {
  transition-delay: 0.5s;
}

.header_nav li a {
  color: #fff;
}

.header_nav.scrl {
  background: rgba(0, 0, 0, 0.95);
  padding: 60px 30px 80px 30px;
  width: 600px;
}

.header_nav_text {
  display: none;
}

.header_inner {
  width: 100%;
  position: relative;
  background: #0a0a0a;
}

.header_inner_text {
  text-align: center;
}

.header_inner_text h1 {
  padding-top: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 59.30495px;
  margin: 0;
  line-height: 1.3em;
  letter-spacing: 0;
}

.header_inner_text div {
  display: none;
}

.header_inner_text p {
  color: #fff;
  margin: 0;
}

.header_inner_text p a {
  color: #fff;
  transition: all 300ms;
}

.header_inner_text p a:hover {
  color: #cccccc;
}

.header_inner_links {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.header_inner_links a {
  position: relative;
  margin: 0 10px;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
}

.header_inner_links .iStyle,
.header_inner_links i {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
}

.header_inner_links .iStyle {
  width: 17px;
  pointer-events: none;
}

.header_inner_links i {
  font-size: 17px;
}

.header_inner_links .i-mail {
  display: none;
}

.header_inner_links .i-cart {
  background: #009a9c;
}

.header_inner_links .i-cart svg {
  fill: #fff;
}

.header_inner_links .i-blog {
  background: #f57d00;
}

.header_inner_links .i-twt {
  background: #1da1f2;
}

.header_inner_links .i-inst {
  background: #c13584;
}

.header_cover {
  visibility: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 15;
  transition: all 300ms;
  background: #0a0a0a;
  opacity: 0;
}

.open .header_hmg span:nth-child(1) {
  transform: translateY(-50%) rotate(45deg);
  top: 70%;
}

.open .header_hmg span:nth-child(2) {
  transform: translateY(50%) rotate(-45deg);
}

.open .header_trg {
  transform: translate(0, -200px);
}

.open .header_nav {
  visibility: visible;
  opacity: 1;
}

.open .header_nav li {
  opacity: 1;
  transform: translate(-300px, 0);
}

.slide-dots {
  position: absolute;
  bottom: 20px;
  z-index: 12;
  list-style-type: none;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0;
}

.slide-dots li {
  margin-right: 32px;
}

.slide-dots li:last-child {
  margin: 0;
}

.slide-dots li div {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 8px solid #fff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.41));
  transition: all 300ms;
}

.slide-dots li div:hover {
  transform: translateY(-3px);
}

.slide-dots li.slick-active div {
  border: 3px solid #fff;
  background: none;
}

.slide-dots li.slick-active div:hover {
  transform: translateY(0px);
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.notice {
  width: 100%;
  background: #eefe05;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding: 1em 1em;
  color: #000;
  display: flex;
  flex-wrap: wrap;
}

.notice p {
  margin: 0.7em 0;
  font-weight: 600;
  font-size: 14px;
}

.notice p a {
  margin: 0.5em;
  text-decoration: underline;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  transition: all 150ms;
}

.notice p a:hover {
  color: #666666;
}

.notice button {
  margin-left: 1em;
  cursor: pointer;
  color: #000;
  display: block;
  background: transparent;
  transition: all 0.3s ease;
  border: 2px solid #000;
  border-radius: 70px;
  padding: 5px 10px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  font-weight: 500;
  outline: none;
  font-size: 12px;
}

.notice button:hover {
  color: #e4e4e4;
  background: #000000;
}

.main {
  background: #e4e4e4;
  padding: 50px 15px;
  margin: 0 auto;
}

.main_wrap {
  max-width: 900px;
  margin: 0 auto;
}

.main .main_flex br {
  display: none;
}

.main .main_flex_text {
  margin: 50px 0;
}

.main .main_flex_text p {
  line-height: 37px;
  margin: 30px 0;
}

.main .main_flex_text .buttons {
  margin-top: 50px;
}

.main .main_flex_text .buttons button {
  margin: 0 auto;
  margin-top: 50px;
  display: block;
  outline: none;
  padding: 11px 30px;
  border: 3px solid #000;
  border-radius: 70px;
  background: transparent;
  font-weight: 500;
  font-size: 22.65248px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.main .main_flex_text .buttons button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #000000;
  color: #e4e4e4;
  transform: translateY(-3px);
}

.main .main_flex_img {
  text-align: center;
}

.main .main_flex_img img {
  max-width: 100%;
  max-height: 600px;
}

.news {
  background-image: url(/images/imgNews.jpg);
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 0 right 25%;
  position: relative;
}

.news::after {
  content: "";
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}

.news_text {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 30px;
  position: relative;
  z-index: 10;
}

.news_text_wrap {
  padding: 15px 0;
  border-top: 1px solid #f5f5f5;
}

.news_text_wrap:last-child {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 40px;
}

.news_text h1 {
  color: #fff;
}

.news_text h3 {
  font-weight: 400;
  min-width: 150px;
  margin: 0;
  color: #fff;
}

.news_text p {
  color: #fff;
  font-weight: 300;
  margin: 8px 0;
}

.news_text a {
  color: #fff;
  margin: 8px 0;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 0.1em;
  font-weight: 300;
  transition: all 300ms;
}

.news_text a:hover {
  color: #e6e6e6;
}

.works {
  background: #e4e4e4;
  padding: 50px 15px;
  margin: 0 auto;
}

.works h3 {
  bottom: 30px;
}

.works_wrap {
  max-width: 900px;
  margin: 0 auto;
}

.works_wrap p {
  margin: 0;
  line-height: 2.5;
  font-size: 22.65248px;
}

.works_wrap button {
  display: block;
  outline: none;
  padding: 11px 30px;
  border: 3px solid #000;
  border-radius: 70px;
  background: transparent;
  font-weight: 500;
  font-size: 22.65248px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 40px auto 20px auto;
}

.works_wrap button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #000000;
  color: #e4e4e4;
  transform: translateY(-3px);
}

.glbg_wo {
  display: flex;
  position: relative;
  bottom: 0;
  z-index: 0;
  width: 100%;
  min-height: 100%;
  background-image: url(/images/works/bgimg001.jpg);
  background-position: bottom 30% center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.woMain_bg {
  background: #e4e4e4;
}

.woMain {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 10px;
  background: #e4e4e4;
}

.woMain #page_top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  background: #1e1e1e;
}

.woMain #page_top a {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

.woMain #page_top a:before {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 900;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
  color: #e4e4e4;
  content: "\f077";
  transform: translate(-50%, -50%);
}

.woMain ul li {
  list-style-type: none;
  margin: 0.5em 0;
  letter-spacing: 0.08em;
  font-size: 14px;
}

.woMain ul {
  padding: 0;
}

.woMain h3 {
  margin: 40px 0;
  font-weight: 500;
}

.woMain h4 {
  margin-left: 0.5em;
  font-weight: 500;
  font-size: 22.65248px;
}

.woMain_index {
  margin-bottom: 100px;
}

.woMain_index h2 {
  font-size: 22.65248px;
}

.woMain_index ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  flex-wrap: wrap;
}

.woMain_index ul a {
  color: #fff;
}

.woMain_index ul li {
  margin: 10px 10px 0 0;
  font-size: 14px;
  display: block;
  outline: none;
  padding: 10px 20px;
  border: 2px solid #000;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.woMain_index ul li:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #000;
  color: #fff;
  transform: translateY(-3px);
}

.woMain_vimeo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.woMain_vimeo div {
  position: relative;
  width: 100%;
  margin: 20px 0;
  padding: 56.25% 0 0 0;
}

.woMain_works {
  width: 80%;
  margin: 0 auto;
}

.woMain_works_text {
  width: 100%;
}

.woMain_works_text button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px 30px;
  border: 2px solid #000;
  display: block;
  outline: none;
  padding: 11px 30px;
  border: 3px solid #000;
  border-radius: 70px;
  background: transparent;
  font-weight: 500;
  font-size: 22.65248px;
  font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.woMain_works_text button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  background: #000000;
  color: #e4e4e4;
  transform: translateY(-3px);
}

.woMain_works_text button p {
  margin: 0;
  font-weight: 500;
}

.woMain_works_inner {
  margin: 50px 0;
}

.woMain_works_inner img {
  max-width: 280px;
  width: 100%;
}

.woMain_works_inner a {
  max-height: 380px;
}

.woMain_works_inner span {
  font-size: 12px;
}

.woMain .workTitle {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
}

.woMain_iframe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.woMain_iframe .ifr_YT img {
  cursor: pointer;
}

.woMain_iframe .ifr_YT_after {
  position: relative;
  padding-top: 56.25%;
}

.woMain_iframe .ifr_YT_after > iframe {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100% !important;
  height: 100% !important;
}

.woMain_iframe_inner {
  width: 100%;
  padding: 40px 0px;
  text-align: center;
}

@media (min-width: 480px) {
  .glHeader_hmg {
    right: 18px;
  }

  .glHeader_hmg_btn {
    width: 40px;
    height: 40px;
  }

  .glHeader_hmg_btn span {
    width: 40px;
  }

  .glbg h1 {
    letter-spacing: 0.2em;
  }

  .glbg_scrIcon {
    display: block;
    transform: translatex(0);
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  .abMain {
    padding: 50px 80px;
  }

  .coMain {
    padding: 50px 80px;
  }

  .evMain {
    padding: 50px 60px;
  }

  .evMain_detail {
    width: 100%;
    max-width: 700px;
  }

  .evMain_detail_inner {
    display: flex;
    justify-content: space-between;
  }

  .evMain_detail_inner div {
    width: 50%;
    text-align: right;
  }

  .evMain_detail_inner .texts {
    margin: 0 20px;
  }

  .pfMain h1 {
    font-size: 67.77709px;
  }

  .pfMain_gallery a {
    width: 25%;
  }

  .pfMain #page_top {
    right: 40px;
    bottom: 40px;
  }

  .header_bg_slide .bgimg_001 {
    background-image: url(/images/bgimg_001.jpg);
  }

  .header_bg_slide .bgimg_002 {
    background-image: url(/images/bgimg_002.jpg);
  }

  .header_bg_slide .bgimg_003 {
    background-image: url(/images/bgimg_003.jpg);
  }

  .header_bg_slide .bgimg_004 {
    background-image: url(/images/bgimg_004.jpg);
  }

  .header_bg_slide .bgimg_005 {
    background-image: url(/images/bgimg_005.jpg);
  }

  .header_trg {
    border-top: 49px solid rgba(0, 0, 0, 0.7);
    border-right: 80px solid rgba(0, 0, 0, 0.7);
    border-bottom: 49px solid transparent;
    border-left: 80px solid transparent;
  }

  .header_hmg {
    right: 18px;
  }

  .header_hmg_btn {
    width: 40px;
    height: 40px;
  }

  .header_hmg_btn span {
    width: 40px;
  }

  .slide-dots li div {
    width: 18px;
    height: 18px;
    border: 9px solid #fff;
  }

  .notice {
    height: 100%;
    padding: 0.7em 0;
  }

  .main {
    padding: 50px;
  }

  .main .main_flex_text .buttons button {
    margin: 60px 20px 0 0;
  }

  .news {
    padding: 0px 0px;
  }

  .news_text a {
    font-size: 16px;
  }

  .news_text_wrap {
    display: flex;
    padding: 15px 0;
    border-top: 1px solid #f5f5f5;
    align-items: center;
    justify-content: space-between;
  }

  .news_text_wrap:last-child {
    border-bottom: 1px solid #f5f5f5;
  }

  .news_text p,
  .news_text h3,
  .news_text a {
    margin: 0;
  }

  .news_text p,
  .news_text a {
    text-align: right;
  }

  .works {
    padding: 50px 50px;
  }

  .works_wrap button {
    margin-right: 0;
    margin-left: 0;
  }

  .works_wrap br {
    display: none;
  }

  .woMain {
    padding: 50px 40px;
  }

  .woMain #page_top {
    right: 30px;
    bottom: 40px;
  }

  .woMain h4 {
    font-size: 22.65248px;
  }

  .woMain_works {
    width: 100%;
    max-width: 700px;
  }

  .woMain_works_inner {
    display: flex;
    justify-content: space-between;
  }

  .woMain_works_inner div {
    width: 50%;
    margin: 0 20px;
    text-align: right;
  }
}

@media (min-width: 480px) and (min-width: 960px) {
  .news_text {
    padding: 10px 150px;
  }

  .news_text br {
    display: none;
  }
}

@media (min-width: 768px) {
  .glHeader_nav ul {
    padding-top: 120px;
    padding-bottom: 50px;
    padding-right: 60px;
  }

  .evMain_list br {
    display: none;
  }

  .header_nav ul {
    padding-top: 120px;
    padding-bottom: 50px;
    padding-right: 60px;
  }

  .main .main_flex {
    display: flex;
    justify-content: space-between;
  }

  .main .main_flex br {
    display: block;
  }

  .main .main_flex_text {
    flex: 0 0 60%;
    margin: 0;
  }

  .main .main_flex_img {
    margin-top: 50px;
  }
}

@media (min-width: 960px) {
  h1 {
    font-size: 41.88854px;
  }

  h3 {
    font-size: 25.88854px;
  }

  p {
    font-size: 16px;
  }

  .glHeader {
    height: 80px;
  }

  .glHeader_hmg {
    display: flex;
    top: 40px;
  }

  .glHeader_hmg_btn {
    width: 30px;
    height: 30px;
  }

  .glHeader_hmg_btn span {
    width: 30px;
  }

  .glHeader_hmg p {
    position: relative;
    cursor: pointer;
    display: block;
    color: #fff;
    margin: auto 10px;
    margin-top: 7.5px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    font-size: 16px;
    line-height: 1;
    transition: all 500ms;
  }

  .glHeader_nav {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    width: 600px;
  }

  .glHeader_nav li {
    font-size: 25.88854px;
  }

  .glHeader_nav ul {
    padding-top: 80px;
    flex-basis: 82%;
  }

  .glHeader_nav_text {
    margin-left: 40px;
    margin-bottom: 30px;
    flex-basis: 18%;
    position: relative;
    display: block;
    transition: all 800ms;
    transform: translateY(520px);
  }

  .glHeader_nav_text h3 {
    color: #fff;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 25.88854px;
  }

  .glHeader_nav_text p {
    color: #fff;
    font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
    font-weight: 100;
    font-size: 9.88854px;
  }

  .glHeader_nav_links {
    transform: translateY(520px);
    bottom: 22%;
    left: 60px;
  }

  .glHeader_inner {
    display: flex;
    height: 80px;
    align-items: center;
  }

  .glHeader_inner_text {
    text-align: left;
    display: flex;
    align-items: center;
    top: 0;
    transform: translateY(0);
    margin-left: 10px;
  }

  .glHeader_inner_text .titleName {
    padding: 0;
    font-weight: 500;
    font-size: 41.88854px;
  }

  .glHeader_inner_text div {
    display: block;
    width: 2px;
    height: 52px;
    background: #fff;
    margin: 0 12px;
  }

  .glHeader_inner_text p {
    display: block;
    align-self: flex-end;
    margin-bottom: 10px;
    font-size: 9.88854px;
  }

  .open .glHeader_hmg span:nth-child(1) {
    top: 50%;
  }

  .open .glHeader_hmg span:nth-child(2) {
    bottom: 50%;
  }

  .open .glHeader_cover {
    visibility: visible;
    opacity: 0.7;
  }

  .abMain ul li {
    font-size: 16px;
  }

  .abMain h3 {
    font-size: 25.88854px;
  }

  .abMain h4 {
    font-size: 25.88854px;
  }

  .abMain .name {
    font-size: 25.88854px;
  }

  .coMain h2 {
    font-size: 25.88854px;
  }

  .evMain h2 {
    font-size: 25.88854px;
  }

  .evMain button {
    font-size: 25.88854px;
  }

  .evMain button {
    font-size: 16px;
  }

  .evMain .status {
    font-size: 16px;
  }

  .evMain_detail {
    max-width: 98%;
    display: flex;
    flex-wrap: wrap;
  }

  .evMain_detail_inner {
    width: 50%;
  }

  .evMain_detail_inner div {
    text-align: left;
  }

  .evMain_detail_inner .bookTitle {
    font-size: 25.88854px;
  }

  .pfMain {
    padding: 60px 0 60px 0;
  }

  .pfMain_wrap {
    margin: 0 auto;
  }

  .pfMain_wrap_index ul li {
    font-size: 16px;
  }

  .pfMain_gallery a {
    width: 20%;
  }

  .header_trg {
    border-top: 49px solid rgba(0, 0, 0, 0.7);
    border-right: 129px solid rgba(0, 0, 0, 0.7);
    border-bottom: 49px solid transparent;
    border-left: 129px solid transparent;
  }

  .header_hmg {
    top: 15px;
    display: flex;
  }

  .header_hmg_btn {
    width: 30px;
    height: 30px;
  }

  .header_hmg_btn span {
    width: 30px;
  }

  .header_hmg p {
    position: relative;
    display: block;
    color: #fff;
    margin: auto 10px;
    margin-top: 7.5px;
    font-weight: 500;
    letter-spacing: 0.15em;
    font-size: 16px;
    line-height: 1;
  }

  .header_nav {
    display: flex;
    flex-flow: column;
    background: rgba(0, 0, 0, 0.7);
    width: 600px;
  }

  .header_nav li {
    font-size: 25.88854px;
  }

  .header_nav ul {
    flex-basis: 78%;
  }

  .header_nav_text {
    margin-left: 40px;
    display: block;
    flex-basis: 22%;
  }

  .header_nav_text h3 {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 25.88854px;
  }

  .header_nav_text p {
    color: #fff;
    font-weight: 100;
    font-size: 11px;
  }

  .header_inner_text {
    text-align: left;
    display: flex;
  }

  .header_inner_text h1 {
    padding: 0;
    margin-left: 30px;
    font-size: 67.77709px;
    flex-shrink: 0;
  }

  .header_inner_text div {
    display: block;
    width: 3px;
    height: 60px;
    background: #fff;
    margin: 0 20px;
    align-self: center;
  }

  .header_inner_text p {
    align-self: flex-end;
    margin-bottom: 18px;
  }

  .header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_inner_links {
    margin: 0;
    margin-right: 20px;
  }

  .header_inner_links .i-mail {
    color: #fff;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
  }

  .header_inner_links .i-mail .mail {
    position: relative;
    width: 45px;
    height: 30px;
    fill: #fff;
    padding-right: 5px;
    transition: all 300ms;
  }

  .header_inner_links .i-mail p {
    margin: 0;
    transition: all 300ms;
  }

  .header_inner_links .i-mail:hover {
    color: #cccccc;
  }

  .header_inner_links .i-mail:hover svg {
    fill: #cccccc;
  }

  .open .header_hmg span:nth-child(1) {
    top: 50%;
  }

  .open .header_hmg span:nth-child(2) {
    bottom: 50%;
  }

  .open .header_cover.scrl {
    visibility: visible;
    opacity: 0.7;
  }

  .slide-dots {
    left: 50px;
    transform: translateX(0);
  }

  .main h1 {
    letter-spacing: 0.1em;
  }

  .main .main_flex_text .buttons {
    margin-top: 100px;
  }

  .main .main_flex_text .buttons button {
    font-size: 25.88854px;
  }

  .works_wrap p {
    font-size: 25.88854px;
  }

  .works_wrap button {
    font-size: 25.88854px;
  }

  .woMain ul li {
    font-size: 16px;
  }

  .woMain h4 {
    font-size: 25.88854px;
  }

  .woMain_index ul li {
    font-size: 16px;
  }

  .woMain_vimeo {
    display: flex;
    flex-direction: row;
  }

  .woMain_vimeo div {
    width: 50%;
    margin: 10px;
    padding: 28.12% 0 0 0;
  }

  .woMain_works {
    display: flex;
    max-width: 98%;
    flex-wrap: wrap;
  }

  .woMain_works_text button {
    font-size: 25.88854px;
  }

  .woMain_works_inner {
    width: 50%;
  }

  .woMain_works_inner div {
    text-align: left;
  }

  .woMain .workTitle {
    font-weight: 700;
    font-size: 16px;
  }

  .woMain_iframe {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .woMain_iframe_inner {
    width: 50%;
    height: 550px;
    padding: 40px 20px;
    text-align: center;
  }
}

@media (min-width: 1600px) {
  .glbg h1 {
    font-size: 137.08204px;
  }
}

@media (min-width: 480px) and (max-width: 960px) {
  .glbg h1 {
    font-size: calc( 54.83282px + 27.41641 * ( ( 100vw - 480px) / 480 ));
  }
}

@media (min-width: 960px) and (max-width: 1600px) {
  .glbg h1 {
    font-size: calc( 82.24923px + 54.83282 * ( ( 100vw - 960px) / 640 ));
  }
}