.glHeader {
  height: 60px;
  @include media(l) {
    height: 80px;
  }
  &_hmg {
    @include z-index(modal);
    position: fixed;
    top: 30px;
    padding: 10px;
    transform: translateY(-50%);
    right: 15px;
    cursor: pointer;
    p {
      display: none;
    }
    &_btn {
      position: relative;
      cursor: pointer;
      width: 25px;
      height: 25px;
      span {
        position: absolute;
        width: 25px;
        height: 2px;
        background: #fff;
        transition: all 500ms;
        &:nth-child(1) {
          top: 30%;
        }
        &:nth-child(2) {
          bottom: 30%;
        }
      }
    }
    @include media(m) {
      right: 18px;
      &_btn {
        width: 40px;
        height: 40px;
        span {
          width: 40px;
        }
      }
    }
    @include media(l) {
      display: flex;
      top: 40px;
      &_btn {
        width: 30px;
        height: 30px;
        span {
          width: 30px;
        }
      }
      p {
        position: relative;
        cursor: pointer;
        display: block;
        color: #fff;
        margin: auto 10px;
        margin-top: 7.5px;
        font-family: $monts;
        font-weight: 500;
        letter-spacing: 0.15em;
        font-size: ms(0, $thread: l);
        line-height: 1;
        transition: all 500ms;
      }
    }
  }
  &_nav {
    position: fixed;
    visibility: hidden;
    bottom: 0;
    padding-left: 20px;
    right: 0px;
    width: 100%;
    min-height: 320px;
    background: rgba($color: #0a0a0a, $alpha: 1);
    opacity: 0;
    transition: all 600ms;
    @include z-index(header);
    ul {
      margin: 0;
      padding: 70px 0 40px 0;
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
    li {
      position: relative;
      right: -300px;
      font-size: ms(1);
      font-family: $monts;
      letter-spacing: normal;
      padding: 0 30px;
      text-align: right;
      font-weight: 500;
      transition: transform 0.4s ease, opacity 0.2s ease;
      font-size: 20px;
      letter-spacing: 0.2em;
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.2s;
      }
      &:nth-child(4) {
        transition-delay: 0.3s;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
      }
      &:nth-child(6) {
        transition-delay: 0.5s;
      }
      a {
        color: #fff;
      }
    }
    &_text {
      display: none;
    }
    @include media() {
      ul {
        padding-top: 120px;
        padding-bottom: 50px;
        padding-right: 60px;
      }
    }
    @include media(l) {
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      width: 600px;
      li {
        font-size: ms(1, $thread: l);
      }
      ul {
        padding-top: 80px;
        flex-basis: 82%;
      }
      &_text {
        margin-left: 40px;
        margin-bottom: 30px;
        flex-basis: 18%;

        position: relative;
        display: block;
        transition: all 800ms;
        transform: translateY(520px);
        h3 {
          color: #fff;
          font-weight: 400;
          margin-top: 0;
          margin-bottom: 10px;
          font-size: ms(1, $thread: l);
        }
        p {
          color: #fff;
          font-family: "Montserrat", "Yu Gothic UI", "Meiryo UI", sans-serif;
          font-weight: 100;
          font-size: ms(-1, $thread: l);
        }
      }
    }
    &_links {
      position: absolute;
      bottom: 20px;
      left: 20px;
      // display: none;
      display: flex;
      transform: translateY(320px);
      flex-direction: column;
      justify-content: center;
      transition: all 500ms;
      @include media(l) {
        transform: translateY(520px);
        bottom: 22%;
        left: 60px;
        // flex-direction: row;
      }
      a {
        position: relative;
        margin: 20px 10px 20px 0;
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        color: #fff;
        border: 1px solid #fff;
      }
      .iStyle,
      i {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        margin: auto;
      }
      .iStyle {
        width: 20px;
        pointer-events: none;
      }
      i {
        font-size: 20px;
      }
      .i-mail {
        display: none;
      }
      .i-cart {
        svg {
          fill: #fff;
        }
      }
    }
  }
  &_inner {
    width: 100%;
    position: fixed;
    top: 0;
    height: 60px;
    background: #0a0a0a;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);
    @include z-index(tooltip);
    @include media(l) {
      display: flex;
      height: 80px;
      align-items: center;
    }
    &_text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 15px;
      .titleName {
        font-family: $monts;
        // padding-top: 10px;
        color: #fff;
        font-weight: 400;
        font-size: ms(1);
        margin: 0;
        line-height: 1em;
        span {
          font-size: ms(-1);
          margin-left: 8px;
          font-weight: 300;
        }
      }
      div {
        display: none;
      }
      p {
        // display: none;
        font-family: $monts;
        color: #fff;
        margin: 0;
        font-size: ms(-2);
        a {
          color: #fff;
          transition: all 300ms;
          &:hover {
            color: darken($color: #fff, $amount: 20%);
          }
        }
      }
      @include media(l) {
        text-align: left;
        display: flex;
        align-items: center;
        top: 0;
        transform: translateY(0);
        margin-left: 10px;
        .titleName {
          padding: 0;
          // height: 50px;
          font-weight: 500;
          font-size: ms(2, $thread: l);
        }
        div {
          display: block;
          width: 2px;
          height: 52px;
          background: #fff;
          margin: 0 12px;
        }
        p {
          display: block;
          align-self: flex-end;
          margin-bottom: 10px;
          font-size: ms(-1, $thread: l);
        }
      }
    }
  }
  &_cover {
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 800px;
    z-index: 15;
    transition: all 300ms;
    background: #0a0a0a;
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-45%);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%);
  }
}
.glbg {
  width: 100%;
  min-height: 400px;
  position: relative;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.2);
  h1 {
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 500;
    margin: 0;
    opacity: 0;
    // transform: translate(-50%, -50%);
    animation: fadeIn 1200ms ease 1000ms forwards;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-shadow: 3px 3px 22px rgba(0, 0, 0, 0.6);
    @include ms-respond(font-size, 4);
    @include media(m) {
      letter-spacing: 0.2em;
    }
  }
  &_scrIcon {
    display: none;
    position: absolute;
    width: 20px;
    margin: 0;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.8));
    // transform: translatex(50%);
    z-index: 3;
    @include media(m) {
      display: block;
      transform: translatex(0);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
    p {
      position: relative;
      margin: 0;
      max-height: 91px;
      margin-bottom: 10px;
      width: 20px;
      font-family: $monts;
      font-weight: 500;
      font-size: ms(0);
      color: #fff;
      writing-mode: vertical-lr;
      letter-spacing: 0.4em;
    }
    .animeIcon {
      position: relative;
      // display: flex;
      // align-items: center;
      width: 20px;
      overflow: hidden;
      flex-flow: column;
      .circle {
        position: absolute;
        top: -15px;
        left: 50%;
        width: 10px;
        height: 10px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: scrlDown 2.5s ease 0s infinite normal;
      }
      .line {
        position: relative;
        top: -95px;
        left: 50%;
        width: 1.5px;
        height: 80px;
        background: #fff;
        animation: scrlDown 2.5s ease 0s infinite normal;
      }
      @keyframes scrlDown {
        0% {
          transform: translate3d(-50%, 0, 0);
        }
        100% {
          transform: translate3d(-50%, 180px, 0);
        }
      }
    }
  }
}
.open .glHeader {
  &_hmg {
    span:nth-child(1) {
      // width: 30px;
      // height: 1.5px;
      top: 70%;
      transform: translateY(-50%) rotate(45deg);
      @include media(l) {
        top: 50%;
      }
    }
    span:nth-child(2) {
      // width: 30px;
      // height: 1.5px;
      transform: translateY(50%) rotate(-45deg);
      @include media(l) {
        bottom: 50%;
      }
    }
  }
  &_nav {
    // transform: translate(-500px, 0);
    visibility: visible;
    opacity: 1;
    li {
      opacity: 1;
      transform: translate(-300px, 0);
    }
    &_text {
      transform: translateY(0);
    }
    &_links {
      transform: translateY(0px);
    }
  }
  &_cover {
    @include media(l) {
      visibility: visible;
      opacity: 0.7;
    }
  }
}
