.pfMain {
  background: #000;
  padding: 30px 2px;
  @include media(l) {
    padding: 60px 0 60px 0;
  }
  .dummy {
    display: none;
  }
  h3,
  p {
    color: #fff;
  }
  h1 {
    color: #fff;
    margin: 0;
    margin-bottom: 70px;
    @include media(m) {
      font-size: ms(3, $thread: l);
    }
  }
  h3 {
    margin-bottom: 8px;
    margin-top: 100px;
  }
  h2 {
    color: #fff;
    font-weight: 500;
  }
  p {
    // font-family: $notos;
    font-weight: normal;
    margin-bottom: 61px;
  }
  &_wrap {
    max-width: 1200px;
    width: 100%;
    padding: 0 5px;
    @include media(l) {
      margin: 0 auto;
    }
    .explanation {
      color: #FFAA2A;
    }
    &_index {
      ul {
        list-style-type: none;
        padding: 0;
        color: #fff;
        font-family: $monts;
        display: flex;
        flex-wrap: wrap;
        a {
          color: #fff;
        }
        li {
          @include button2;
          font-size: ms(0, $thread: s);
          margin: 10px 10px 0 0;
        }
      }
    }
  }
  &_gallery {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    a {
      margin: 0;
      position: relative;
      // max-width: 200px;
      width: 50%;
      // max-height: 200px;
      padding: 2px;
      figure {
        display: none;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        transition: 0.4s;
      }
      &:hover {
        img {
          opacity: 0.7;
        }
      }
      &.ok {
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-image: url(/images/license.png);
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          top: 0;
          left: 0;
        }
      }
    }
    @include media(m) {
      a {
        width: 25%;
      }
    }
    @include media(l) {
      a {
        width: 20%;
      }
    }
  }
  @include topButton2;
}

.okimg {
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.5em;
  position: absolute;
  top: 0%;
  bottom: 9%;
  margin: auto;
}
// width: 650px;
// height: 205px;
// font-family: "Yu Gothic UI";
// font-weight: normal;
// font-size: 18px;
// letter-spacing: 0.1em;
// line-height: 34px;
// text-align: left;
// color: #021313;
// border: 1px solid rgba(0, 0, 0, 0);
